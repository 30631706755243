import {useEffect, useState} from 'react'
import { useCurrent,usePut,useFetch } from '../../hooks';
import ServiceForm from './form/ServiceForm';
import {  MwSpinnerButton } from '../../components/ui';
import { useNavigate, useParams } from 'react-router-dom';

const EditService = ({
    onSubmitCallBack = ()=>{}
}) => {
    const params = useParams();
    const navigate = useNavigate();
    const [formState,setFormState] = useState({
        serviceName: '',
        serviceDesc: '',
    });
    const [errors,setErrors] = useState();
    const {currentTenantId,currentBranchId,currentCompanyId} = useCurrent(); 
    const {data:servicesResult,loading:servicesLoading} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/services/${params.serviceId}`);

    const {data:putResult,loading:putLoading,putData} = usePut();

    const saveHanlder = () => {
        setErrors({});
        
        if(!formState.serviceName){
            setErrors((state) =>({...state,serviceName:'يجب ادخال اسم الخدمة'}));
            return;
        }

        const _data = {
            "serviceName": formState.serviceName,
            "serviceDesc": formState.serviceDesc,
            // "isDefault": true,
            "isActive" : true,
            "tenantId": currentTenantId,
            "branchId": currentBranchId,
            "companyId": currentCompanyId
        }
        putData(`${process.env.REACT_APP_RATING_API_BASE_URL}/services/${params.serviceId}`,_data)
        console.log(_data);
    }

    useEffect(() => {
        if(putResult){
            navigate(`/${params.tenant}/rating-services`)
        }
    },[putResult])

    useEffect(()=>{
        if(servicesResult){
            setFormState({
                serviceName: servicesResult.data.serviceName,
                serviceDesc: servicesResult.data.serviceDesc,
            })

        }
    },[servicesResult]);

    return (
        <div>
            {
                !servicesLoading ?
                    <ServiceForm
                        errors = {errors}
                        formState = {formState}
                        setFormState = {setFormState}
                        saveHanlder = {saveHanlder}
                        title='تعديل خدمة'
                    /> :
                    <MwSpinnerButton/>
            }
        </div>
    )
}

export default EditService