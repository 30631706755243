import {useEffect, useState} from 'react'
import { useCurrent,usePost } from '../../hooks';
import ServiceForm from './form/ServiceForm';

const AddNewRatingService = ({
    onSubmitCallBack = ()=>{}
}) => {
    const [formState,setFormState] = useState({
        serviceName: '',
        serviceDesc: '',
    });
    const [errors,setErrors] = useState();
    const {currentTenantId,currentBranchId,currentCompanyId} = useCurrent(); 
    const {data:postResult,loading:postLoading,postData} = usePost();

    const saveHanlder = () => {
        setErrors({});
        
        if(!formState.serviceName){
            setErrors((state) =>({...state,serviceName:'يجب ادخال اسم الخدمة'}));
            return;
        }

        const _data = {
            "serviceName": formState.serviceName,
            "serviceDesc": formState.serviceDesc,
            // "isDefault": true,
            "isActive" : true,
            "tenantId": currentTenantId,
            "branchId": currentBranchId,
            "companyId": currentCompanyId
        }
        postData(`${process.env.REACT_APP_RATING_API_BASE_URL}/services`,_data)
        console.log(_data);
    }

    useEffect(() => {
        if(postResult){
            onSubmitCallBack();
        }
    },[postResult])

    return (
        <div>
            <ServiceForm
                    errors = {errors}
                    formState = {formState}
                    setFormState = {setFormState}
                    saveHanlder = {saveHanlder}
                />
        </div>
    )
}

export default AddNewRatingService