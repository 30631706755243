import React, { useEffect,useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCurrent, useFetch } from '../../hooks';
import RatingList from './RatingList';
import RatingBox from '../../components/ui/RatingBox';
import QRCode from 'react-qr-code';

const RatingManager = () => {
    const params = useParams();
    const [serviceRatingSum,setServiceRatingSum] = useState(0);
    const [employeeRatingSum,setEmployeeRatingSum] = useState(0);
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    const {data:ratingResult,loading:ratingLoading} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${params.serviceId}`);
    const {data:servicesResult,loading:servicesLoading,refreshHandler} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/services/${params.serviceId}`);

    const _qrValue = `${process.env.REACT_APP_RATING_WEB_USER_BASE_URL}/${params.tenant}/${params.serviceId}` ;

    useEffect(()=>{
        if(ratingResult){
            console.log('ratingResult => ',ratingResult)

            const _serviceRatingSum = ratingResult?.data.reduce((a,b)=> a + b.ratingService ,0);
            const _rateService = _serviceRatingSum > 0 ? Math.round((_serviceRatingSum / (ratingResult?.data.length )  )) : 0;
            setServiceRatingSum(_rateService);

            const _employeeRatingSum = ratingResult?.data.reduce((a,b)=> a + b.ratingEmployee ,0);
            const _rateEmployee = _employeeRatingSum > 0 ? Math.round((_employeeRatingSum / (ratingResult?.data.length ) ) ) : 0;
            setEmployeeRatingSum(_rateEmployee);

            console.log('_rateService',_rateService,_rateEmployee)

        }
    },[ratingResult]);

    useEffect(()=>{
        if(servicesResult){
            console.log('servicesResult => ',servicesResult);

        }
    },[servicesResult]);


    return (
        <div>
            <div className='flex gap-2 justify-between'>
                <div className='flex-1 flex flex-col justify-between '>
                    <div>
                        { servicesResult && <div className='text-slate-700 font-bold p-3'>{servicesResult?.data?.serviceName}</div> }
                        <hr className='mb-3 mx-3'/>
                    </div>
                    {
                        servicesResult?.data?.serviceDesc &&
                            <div className=' h-24 overflow-auto mb-3'>
                                <div className='text-xs text-slate-400 flex '>{servicesResult?.data?.serviceDesc}</div>
                            </div>
                    }
                    <div>
                        <hr className='mb-3 mx-3'/>
                        <div className='flex justify-between  gap-1 text-xs'>
                            <div className='flex flex-col items-center gap-1 px-3 '>
                                <span>اجمالي تقييم الخدمة  </span>
                                <RatingBox rate={serviceRatingSum} size={26}/>
                            </div>
                            <div className='flex flex-col items-center gap-1 px-3 '>
                                <span>اجمالي تقييم الموظف  </span>
                                <RatingBox rate={employeeRatingSum} size={26}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-60'>
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={_qrValue}
                        viewBox={`0 0 256`}
                        />
                </div>
            </div>
            <hr className='my-3'/>
            <RatingList data={ratingResult} loading={ratingLoading} />
        </div>
    )
}

export default RatingManager