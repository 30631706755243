import React from 'react'
import RatingServicesListItem from './RatingServicesListItem'
import { MwButton, MwSpinnerButton, NoDataFound } from '../../components/ui'

const RatingServicesList = ({
    showConfirmDelete,
    setShowConfirmDelete,
    deleteLoading,
    deleteHandler,
    addNewHandler,
    data,
    loading}) => {
    return (
        <div className='h-full '>
            {
                loading ? 
                    <MwSpinnerButton isFullCenter={true} withLabel={false}/>
                    : data.data.length > 0 ?
                        <div className='grid grid-cols-4 gap-3'>
                            {
                                data.data.map( (item) => <RatingServicesListItem 
                                showConfirmDelete ={showConfirmDelete}
                                setShowConfirmDelete ={setShowConfirmDelete}
                                deleteLoading={deleteLoading} 
                                deleteHandler={deleteHandler} 
                                key={item._id} 
                                item={item}/>)
                            }
                        </div>:
                        <NoDataFound msg={`لا يوجد خدمات تقييم   `}>
                            <div className='flex gap-3  justify-center items-center'>
                                <MwButton onClick={addNewHandler} type='saveBtn'>أنشاء خدمة جديدة</MwButton> 
                            </div>
                        </NoDataFound>
            }
            
        </div>
    )
}

export default RatingServicesList