import React, { useEffect,useState } from 'react'
import { useCurrent, useDelete, useFetch } from '../../hooks'
import {CardHeaderWithActions, MwButton} from '../../components/ui'
import AddNewRatingService from './AddNewRatingService'
import RatingServicesList from './RatingServicesList'

const RatingServicesManager = () => {
    const [mode,setMode] = useState('Listing');
    const {currentTenantId,currentBranchId,currentCompanyId} = useCurrent();
    const {data:servicesResult,loading:servicesLoading,refreshHandler} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/services/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:deleteResult,loading:deleteLoading,deleteItem} = useDelete();
    const [showConfirmDelete,setShowConfirmDelete] = useState(false);

    const addNewHandler = () => {
        setMode('Adding');
    }
    const ListingHandler = () => {
        setMode('Listing');
    }
    const onSubmitCallBack = () =>{
        ListingHandler();
        refreshHandler();
    }

    const deleteHandler = (id) => {
        const _deleteUrl = `${process.env.REACT_APP_RATING_API_BASE_URL}/services/${id}`;
        deleteItem(_deleteUrl);
    }

    useEffect(()=>{
        if(servicesResult){
            if(servicesResult.data.length === 0){
                setMode('Adding')
            }
            console.log('servicesResult => ',servicesResult);
        }
    },[servicesResult]);

    useEffect(()=>{
        if(deleteResult){
            setShowConfirmDelete(false);
            refreshHandler();
        }
    },[deleteResult]);


    return (
        <div className='h-full flex flex-col '>
            <CardHeaderWithActions title={`ادارة خدمات التقييم`}>
                {mode !== 'Adding' && <MwButton onClick={addNewHandler} type='saveBtn'>أنشاء خدمة جديدة</MwButton> }
                {mode === 'Adding' && <MwButton onClick={ListingHandler} type='secondary'>ادارة الخدمات</MwButton> }
            </CardHeaderWithActions>
            <div className='h-full '>
                {mode ==='Adding' && <AddNewRatingService onSubmitCallBack={onSubmitCallBack}/>}
                {mode ==='Listing' && <RatingServicesList 
                                        showConfirmDelete ={showConfirmDelete}
                                        setShowConfirmDelete ={setShowConfirmDelete}
                                        deleteLoading={deleteLoading} 
                                        deleteHandler={deleteHandler} 
                                        addNewHandler={addNewHandler}
                                        data={servicesResult} 
                                        loading={servicesLoading} 
                                        />}
            </div>
        </div>
    )
}

export default RatingServicesManager