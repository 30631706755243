import React from 'react'
import { MwInputText,MwTextArea,MwButton, TitleGroup } from '../../../components/ui'

const ServiceForm = ({
    errors,
    formState,
    setFormState,
    saveHanlder,
    title = 'اضف خدمة جديدة'
}) => {
    return (
        <div className='flex flex-col gap-3 justify-between rounded-xl bg-slate-100 p-3 '>
            <TitleGroup>{title}</TitleGroup>
            <MwInputText
                label='اسم الخدمة'
                value={formState.serviceName}
                onChange={(e)=>setFormState((state) => ({...state,serviceName:e.target.value}))}
                invalid={!!errors?.serviceName}
                invalidMsg={errors?.serviceName}
                />
            <MwTextArea
                label='وصف الخدمة'
                value={formState.serviceDesc}
                onChange={(e)=>setFormState((state) => ({...state,serviceDesc:e.target.value}))}
                invalid={!!errors?.serviceDesc}
                invalidMsg={errors?.serviceDesc}
                />
            <div className='flex justify-end'>
                <MwButton onClick={saveHanlder} type='saveBtn' ><span className='pt-1'>حفظ</span></MwButton>
            </div>
            
    </div>
    )
}

export default ServiceForm