import {useEffect, useState} from 'react'
import {  useTranslation } from 'react-i18next';
import { useCurrent, useFetch } from '../hooks';
import { useParams } from 'react-router-dom';
import DashboradNavBar from '../components/dashborad/DashboradNavBar';
import MonthlyLineChart from '../components/dashborad/MonthlyLineChart';

const DashBoard = () => {

    const [t] = useTranslation('global')
    const {currentTenantId,currentCompanyId,currentBranchId} = useCurrent();
    // let salesCurrentYear = 0;
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    // const currentYeay = date.getFullYear();
     // const Sales Months groups
    const [serviceSelected,setServiceSelected] = useState();
    const [ratingByService,setRatingByService] = useState([]);
    const [ratingByServiceLoading,setRatingByServiceLoading] = useState(true);
    const tenantUsername = useParams()?.tenant;
    const [servicesRate,setServicesRate] = useState(0);
    const [employeesRate,setEmployeesRate] = useState(0);
    
    const {data:servicesResult,loading:servicesLoading,refreshHandler} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/services/${currentTenantId}/${currentCompanyId}/${currentBranchId}`);
    const {data:ratingResult,loading:ratingLoading,fetchData} = useFetch(`${process.env.REACT_APP_RATING_API_BASE_URL}/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${serviceSelected?._id}`,false);


    const ratingByServiceHandler = async () => {
        // const res = await axios.get(`${process.env.REACT_APP_INV_API_SERVER_BASE_URL}/invoices/month-group/${currentTenantId}/${currentCompanyId}/${currentBranchId}/${currentYeay}`);
        // const sum = res.data;
        setRatingByService([
            // "3",
            // "4",
            // "4",
            // "5",
            // "2",
            // "5",
            // "5",
            // "5",
            // "4",
            // "5",
            // "5",
            // "5",
        ])
        setRatingByServiceLoading(false)
    }


    useEffect(() =>{
        ratingByServiceHandler();
    },[]);

    useEffect(() =>{
        if(servicesResult){
            let _rand = Math.round(Math.random() * (servicesResult?.data.length - 0) + 0);
            if(_rand >= servicesResult?.data.length ){
                _rand = _rand -1;
            }
            console.log('_rand',_rand);
            setServiceSelected(servicesResult?.data[_rand]);
            console.log('servicesResult => ',servicesResult);
        }
    },[servicesResult]);

    useEffect(() =>{
        if(serviceSelected){
            fetchData();
        }
    },[serviceSelected]);

    useEffect(() =>{
        if(servicesResult && ratingResult){
            console.log('ratingResult => ',ratingResult);
            let _monthlyRating=[];
            
            [1,2,3,4,5,6,7,8,9,10,11,12].forEach(month =>{
                let sumMonthly = 0;
                ratingResult.data.map( res => {
                    const _date = new Date(res.createdAt);
                    if( _date.getMonth() + 1 === month ){
                        if(res.ratingService){
                            sumMonthly += parseInt(res.ratingService || 0)
                        }
                    }
                })
                _monthlyRating.push(sumMonthly)
            })
            console.log( '_monthlyRating => ',_monthlyRating)
            setRatingByService(_monthlyRating)
            
        }
    },[ratingResult,servicesResult]);

    return (
        <>
        <div className='h-full gap-3 flex flex-col '>
            <DashboradNavBar
                servicesLoading={servicesLoading}
                serviceLength = {servicesResult?.data?.length}
                ratingLoading = {false}
                ratingLength = {`0`}
                servicesRate = {servicesRate}
                employeesRate = {employeesRate}
                />
            <hr className='mb-2 mx-3' />
            <MonthlyLineChart
                    tenantUsername = {tenantUsername}
                    servicesLoading = {servicesLoading}
                    servicesResult = {servicesResult}
                    serviceSelected = {serviceSelected}
                    ratingByService = {ratingByService}
                    ratingByServiceLoading = {ratingByServiceLoading}
                />
        </div>
        </>
    )
}

export default DashBoard