import React,{useState} from 'react'
import QRCode from 'react-qr-code'
import { Link, useParams } from 'react-router-dom';
import {  ConfirmDelete, MwInputText, MwSpinnerButton } from '../../components/ui';
import { FaRegEdit } from "react-icons/fa";
import { LuPrinter } from "react-icons/lu";
import { AiOutlineDelete } from "react-icons/ai";


const RatingServicesListItem = ({showConfirmDelete,setShowConfirmDelete,deleteLoading,deleteHandler,item}) => {
    const params = useParams();
    const _qrValue = `${process.env.REACT_APP_RATING_WEB_USER_BASE_URL}/${params.tenant}/${item._id}` ;
    const [itemSelected,setItemSelected] = useState();
    
    const Print = () =>{     
        //console.log('print');  
        let printContents = document.getElementById(`printablediv-${item._id}`).innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        
        }
    const deleteItemHandler = () =>{
        if(itemSelected){
            deleteHandler(itemSelected);
        }
    }

    return (
        <>
            <ConfirmDelete
                setOnShow={setShowConfirmDelete}
                onShow={showConfirmDelete}
                onDeleteHandler={deleteItemHandler}
                loading={deleteLoading}
                msg = 'هل انت متاكد من حذف الخدمة'
                />
            {
                !deleteLoading ? 
                <div className='relative border border-slate-200 rounded-xl bg-slate-100 p-5  text-slate-600 hover:bg-slate-200' >
                    <Link to={`/${params.tenant}/rating/${item._id}`} >
                    <div className=' pt-4 pb-2   text-center  '>
                        <span className=' text-sm '>{item.serviceName}</span>
                        {item.isDefault && <span className='absolute top-2 end-2 rounded-lg text-xs bg-green-50 px-3 py-1 '> الافتراضية </span>}
                        <span className={`absolute top-2 start-2 rounded-lg text-xs ${item.isActive ? 'bg-green-50' : 'bg-slate-100' } px-3 py-1 `}> {item.isActive ? 'مفعل' : 'غير مفعل' } </span>
                    </div>
                    <div>
                        <QRCode
                            size={128}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={_qrValue}
                            viewBox={`0 0 128`}
                            />
                    </div>
                    </Link>
                    <div className='pt-3 text-xs '>
                        <MwInputText
                            label='رابط التقييم'
                            value={_qrValue}
                            disabled={true}
                            onChange={()=>{}}
                            />
                    </div>
                    <div className='pt-4 flex gap-2 justify-between items-center'>
                        <div className='flex gap-3'>
                            <LuPrinter onClick={Print} className='hover:text-slate-900' size={16}/>
                            <Link to={`/${params.tenant}/rating-services/edit/${item._id}`}>
                                <FaRegEdit className='hover:text-slate-900' size={16}/>
                            </Link>
                        </div>
                        <AiOutlineDelete onClick={()=>{
                            setItemSelected(item._id);
                            setShowConfirmDelete(true);
                            }} size={16}/>
                    </div>
                </div> :
                <MwSpinnerButton withLabel={false} isFullCenter={true} />
            }
            <div id={`printablediv-${item._id}`} className='hidden flex flex-col justify-center items-center w-full  ' >
                <div className='w-full flex flex-col justify-center items-center '>
                    <span className='text-center p-3 text-3xl font-bold '> تقييم خدمة</span>
                    <span className='text-center p-3 text-2xl font-bold '>{item.serviceName}</span>
                    <span className='text-center p-3 text-xl font-bold '>
                        من فضلك قم بقراءة الكود باستخدام جوالك لتقييم الخدمة
                    </span>
                    <QRCode
                        className='w-80'
                        size={128}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={_qrValue}
                        viewBox={`0 0 128`}
                        />
                    <div className='p-2 flex justify-between  '>
                        <span></span>
                        <span>Develop By www.Absat.org</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RatingServicesListItem