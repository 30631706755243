import React from 'react'
import { MdStarRate } from "react-icons/md";

const RatingBox = ({rate=0,size=16}) => {
    const ratingLimit = 5;

    return (
        <div className=' flex justify-center items-center  '>
            <div className='flex gap-1 justify-center items-center '>
                {
                    [...Array(ratingLimit-rate)].map((item,i) =>(<MdStarRate className='text-slate-500' key={i} size={size} />))
                }
                {
                    [...Array(rate)].map((item,i) =>(<MdStarRate className='text-yellow-500' key={i} size={size} />))
                }
            </div>
        </div>
    )
}

export default RatingBox