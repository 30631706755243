import React from 'react'
import CardSummaryWithLoading from '../ui/card/CardSummaryWithLoading'
import RatingBox from '../ui/RatingBox'

const DashboradNavBar = ({
    servicesLoading,
    serviceLength,
    ratingLoading,
    ratingLength,
    servicesRate,
    employeesRate
}) => {
    return (
        <div className='h-32 flex gap-3 justify-between items-center'>
                <div className='flex-1 flex gap-3'>
                    <CardSummaryWithLoading
                        label={`عدد الخدمات`}
                        loading = {servicesLoading}
                        data={serviceLength}
                        icon = '/assets/icons/icons8-service-100.png'
                        />
                    <CardSummaryWithLoading
                        label={`عدد التقيمات`}
                        loading = {ratingLoading}
                        data={ratingLength}
                        icon = '/assets/icons/icons8-rating-96.png'
                        />
                </div>

                <CardSummaryWithLoading reverse= {true} label={`اجمالي التقيمات لجميع الخدمات`}>
                    <RatingBox size={26} rate={servicesRate}/>
                </CardSummaryWithLoading>

                <CardSummaryWithLoading reverse= {true} label={`اجمالي التقيمات لجميع الموظفين`}>
                    <RatingBox size={26} rate={employeesRate}/>
                </CardSummaryWithLoading>
            </div>
    )
}

export default DashboradNavBar